<template>
  <div>
    <span class="font-title-medium">同步信息</span>
    <div class="form-container-border">
      <el-row type="flex">
        <el-col :span="3" class="form-border form-left-bg font-small">同步品牌</el-col>
        <el-col class="form-border font-small" :span="5">
          <el-button size="mini" type="primary" @click="incSyncBand">增量同步</el-button>
          <el-button size="mini" type="primary" @click="fullSyncBrand">全量同步</el-button>
        </el-col>
        <el-col class="form-border font-small" :span="16">
          当前状态：{{brandSyncStatus}}
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="3" class="form-border form-left-bg font-small">同步分类</el-col>
        <el-col class="form-border font-small" :span="5">
          <el-button size="mini" type="primary" @click="incSyncCategory">增量同步</el-button>
          <el-button size="mini" type="primary" @click="fullSyncCategory">全量同步</el-button>
        </el-col>
        <el-col class="form-border font-small" :span="16">
          当前状态：{{categorySyncStatus}}
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="3" class="form-border form-left-bg font-small">同步商品</el-col>
        <el-col class="form-border font-small" :span="5">
          <el-button size="mini" type="primary" @click="incSyncSpu">增量同步</el-button>
          <el-button size="mini" type="primary" @click="fullSyncSpu">全量同步</el-button>
        </el-col>
        <el-col class="form-border font-small" :span="16">
          当前状态：{{spuSyncStatus}}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { syncBrandRequest, syncCategoryRequest, syncSpuRequest, incrSyncSpuRequest } from '../../../network/supply'

export default {
  name: 'SyncDataPane',
  props: {
    supplierId: {
      type: Number,
      default: 0
    },
    brandSyncStatus: {
      type: String,
      default: '无同步'
    },
    categorySyncStatus: {
      type: String,
      default: '无同步'
    },
    spuSyncStatus: {
      type: String,
      default: '无同步'
    }
  },
  data () {
    return {
    }
  },
  methods: {
    incSyncBand () {
      this.$confirm('todo', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.alertMessage('操作成功', 'success')
      }).catch(() => {
        this.alertMessage('已取消操作', 'info')
      })
    },
    fullSyncBrand () {
      this.$confirm('全量同步将花费较长时间，操作后请查看请求状态', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        syncBrandRequest({ supplierId: this.supplierId }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('品牌数据正在同步中，请完成后再重新进行同步', 'error')
          }
          this.alertMessage('操作成功，请等待同步完成', 'success')
        })
      }).catch(() => {
        this.alertMessage('已取消操作', 'info')
      })
    },
    incSyncCategory () {
      this.$confirm('todo', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.alertMessage('操作成功', 'success')
      }).catch(() => {
        this.alertMessage('已取消操作', 'info')
      })
    },
    fullSyncCategory () {
      this.$confirm('全量同步将花费较长时间，操作后请查看请求状态', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        syncCategoryRequest({ supplierId: this.supplierId }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('分类数据正在同步中，请完成后再重新进行同步', 'error')
          }
          this.alertMessage('操作成功，请等待同步完成', 'success')
        })
      }).catch(() => {
        this.alertMessage('已取消操作', 'info')
      })
    },
    incSyncSpu () {
      this.$confirm('将进行增量同步', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        incrSyncSpuRequest({ supplierId: this.supplierId }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('商品正在增量同步中，请完成后再重新进行同步', 'error')
          }
          this.alertMessage('操作成功，请等待同步完成', 'success')
        })
      }).catch(() => {
        this.alertMessage('已取消操作', 'info')
      })
    },
    fullSyncSpu () {
      this.$confirm('全量同步将花费较长时间，操作后请查看请求状态', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        syncSpuRequest({ supplierId: this.supplierId }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('商品数据正在同步中，请完成后再重新进行同步', 'error')
          }
          this.alertMessage('操作成功，请等待同步完成', 'success')
        })
      }).catch(() => {
        this.alertMessage('已取消操作', 'info')
      })
    }
  }
}
</script>

<style scoped>
.detail-container {
}

.standard-margin {
  margin-top: 15px;
}
.form-border {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
}

.form-container-border {
  border-left: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
  margin-top: 15px;
}

.form-left-bg {
  background: #F2F6FC;
}
.form-color {
  color: red;
}
</style>
