<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>供应链管理</template>
      <template v-slot:secondMenu>数据同步</template>
    </breadcrumb-nav>
    <el-card shadow="never">
      <el-form :inline="true">
        <el-form-item label="供应商">
          <el-select
            placeholder="请选择供应商"
            v-model="supplierId"
            @change="supplierChange"
          >
            <el-option
              :label="item.desc"
              :value="item.supplierId"
              v-for="item in supplierList"
              :key="item.supplierId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="activeName==='sync'">
          <el-button type="primary" @click="getSyncStatus">获取当前最新同步状态</el-button>
        </el-form-item>
        <el-form-item label="同步类型" v-if="activeName==='record'">
          <el-select
            placeholder="同步类型"
            v-model="listQuery.syncType"
            @change="syncTypeChange"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in syncTypeOptions"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="activeName==='record'">
          <el-button type="primary" @click="getRecordList">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px" shadow="never" class="standard-margin">
      <el-tabs v-model="activeName" @tab-click="tabClicked">
        <el-tab-pane label="同步操作" name="sync">
          <sync-data-pane ref="sync"
                          :supplier-id="supplierId"
                          :brand-sync-status="brandSyncStatus"
                          :category-sync-status="categorySyncStatus"
                          :spu-sync-status="spuSyncStatus"
          ></sync-data-pane>
        </el-tab-pane>
        <el-tab-pane label="同步记录" name="record">
          <sync-record-pane
            :supplier-id="supplierId"
            :table-data="recordList"
            :list-query="listQuery"
            :total="total"
            @page-size-change="handleSizeChange"
            @current-page-change="handleCurrentChange"
          ></sync-record-pane>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import SyncDataPane from './syncChildComponents/SyncDataPane'
import SyncRecordPane from './syncChildComponents/SyncRecordPane'
import {
  getSupplierListRequest, getSyncRecordRequest, getSyncStatusRequest
} from '../../network/supply'

export default {
  name: 'Sync',
  components: {
    SyncRecordPane,
    BreadcrumbNav,
    SyncDataPane
  },
  filters: {
  },
  data () {
    return {
      supplierId: 0,
      supplierList: [],
      brandSyncStatus: '',
      categorySyncStatus: '',
      spuSyncStatus: '',
      activeName: 'sync',
      recordList: [],
      listQuery: {
        page: 1,
        size: 10,
        supplierId: 0,
        syncType: 0
      },
      total: 0,
      syncTypeOptions: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '全量同步品牌'
        },
        {
          value: 2,
          label: '增量同步品牌'
        },
        {
          value: 3,
          label: '全量同步分类'
        },
        {
          value: 4,
          label: '增量同步分类'
        },
        {
          value: 5,
          label: '全量同步商品'
        },
        {
          value: 6,
          label: '增量同步商品'
        }
      ]
    }
  },
  mounted () {
    this.getSupplierList()
  },
  methods: {
    tabClicked (tab, event) {
      switch (tab.name) {
        case 'sync':
          this.getSyncStatus()
          break
        case 'record':
          this.getRecordList()
          break
        default:
          console.log('unknown tab:', tab.name)
      }
    },
    supplierChange (value) {
      // console.log('supplier value:', value)
      this.supplierId = value
      this.listQuery.supplierId = this.supplierId
      this.getSyncStatus()
      this.getRecordList()
    },
    syncTypeChange (value) {
      this.listQuery.syncType = value
      // console.log('this.syncType:', this.listQuery.syncType)
    },
    getSupplierList () {
      getSupplierListRequest().then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取供应商列表失败', 'error')
        }
        this.supplierList = result.data.list
        if (this.supplierList.length > 0) {
          this.supplierId = this.supplierList[0].supplierId
          this.listQuery.supplierId = this.supplierId
          this.getSyncStatus()
        }
      })
    },
    getSyncStatus () {
      getSyncStatusRequest({ supplierId: this.supplierId }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取同步状态失败', 'error')
        }
        this.brandSyncStatus = res.data.data.brand
        this.categorySyncStatus = res.data.data.category
        this.spuSyncStatus = '全量：' + res.data.data.spu + '；增量：' + res.data.data.incr_spu
      })
    },
    getRecordList () {
      getSyncRecordRequest(this.listQuery).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取同步记录失败', 'error')
        }
        this.recordList = res.data.data.list
        this.total = res.data.data.total
        // console.log('--------this.record list:', res.data.data.list)
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      // console.log('-------change size:', newSize)
      this.getRecordList()
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getRecordList()
    }
  }
}
</script>
