<template>
  <div>
    <el-table :data="tableData" border stripe style="margin-top: 10px;">
      <el-table-column width="30" type="index" label="#"></el-table-column>
      <el-table-column label="同步类型" width="200" prop="name">
        <template slot-scope="scope">{{ scope.row.syncType | syncTypeLabel }}</template>
      </el-table-column>
      <el-table-column width="100" label="成功条数" prop="succ">
      </el-table-column>
      <el-table-column width="100" label="失败条数" prop="failed">
        <template slot-scope="{row}">
          <div v-if="row.failed>0">
            <el-tag @click="showFailedDetail(row)" style="margin-left: 10px">{{row.failed}}   ?</el-tag>
          </div>
          <span v-if="row.failed<=0">{{row.failed}}</span>
        </template>
      </el-table-column>
      <el-table-column width="100" label="添加条数" prop="added">
      </el-table-column>
      <el-table-column width="100" label="更新条数" prop="updated">
      </el-table-column>
      <el-table-column width="100" label="同步总条数" prop="total">
      </el-table-column>
      <el-table-column label="同步结束时间" prop="createTime" :formatter="formatDate">
      </el-table-column>
      <el-table-column label="同步消耗时间" prop="spendTime" :formatter="convertMillisecondsToHMS">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[5, 10, 20]"
      :page-size="listQuery.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'SyncRecordPane',
  filters: {
    syncTypeLabel (value) {
      switch (value) {
        case 1:
          return '全量同步品牌'
        case 2:
          return '增量同步品牌'
        case 3:
          return '全量同步分类'
        case 4:
          return '增量同步分类'
        case 5:
          return '全量同步商品'
        case 6:
          return '增量同步商品'
      }
    }
  },
  props: {
    tableData: {
      type: Array,
      default () {
        return []
      }
    },
    listQuery: {
      type: Object,
      default () {
        return {
          page: 1,
          size: 10
        }
      }
    },
    total: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleSizeChange (newSize) {
      this.$emit('page-size-change', newSize)
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.$emit('current-page-change', newPage)
    },

    formatDate (row, column) {
      const value = row.createTime
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      const hour = (date.getHours() + '').padStart(2, '0')
      const minute = (date.getMinutes() + '').padStart(2, '0')
      const second = (date.getSeconds() + '').padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    },
    convertMillisecondsToHMS (row, column) {
      const milliseconds = row.spendTime
      // 计算总共有多少秒
      var totalSeconds = Math.floor(milliseconds / 1000)
      // 计算有多少小时
      var hours = Math.floor(totalSeconds / 3600)
      // 剩余的秒数
      var secondsLeft = totalSeconds % 3600
      // 计算分钟数
      var minutes = Math.floor(secondsLeft / 60)
      // 剩余的秒数
      var seconds = secondsLeft % 60
      // 把时、分、秒都补全成两位数
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      // 组合时分秒
      return hours + '时:' + minutes + '分:' + seconds + '秒'
    },
    showFailedDetail (row) {
      console.log('go failed row:', row)
      this.$router.push({ path: '/failed', query: { recordId: row.recordId, supplierId: row.supplierId } })
    }
  }
}
</script>

<style scoped>

</style>
